body {
  background-color: #123c4c;
}


.App {
  text-align: center;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}


.modalpage {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: #123c4c;
}

.modalpage.visible {
  opacity: 1;
  display: block;
  animation: modal-show 1s;
}
.modalpage.hidden {
  opacity: 0;
  visibility: hidden;
  animation: modal-hide 1s;
}

@keyframes modal-show {
    0% { opacity: 0; visibility: hidden;}
    1% { opacity: 0; visibility: visible; }
    100% { opacity: 1; visibility: visible; }
}

@keyframes modal-hide {
    0% { opacity: 1; visibility: visible; }
    99% { opacity: 0; visibility: visible; }
    100% { opacity: 0; visibility: hidden; }
}

.contentbox {
    background-color: #ffffffcf;
    min-height: 700px;
    margin: 20px;
    border-radius: 10px;
    padding: 10px;
    position: relative;
}

.rulesrow {
  margin: 2px auto;
  max-width: 600px;
  padding: 15px 0;
  display: flex;
  border-top: 1px solid #0000006b;
  }

.rulesrow.selected {
  border: 2px solid #00f;
}

.rulesrow .tile {
  background-color: #5f5f5f85;
  background-image: url('./assets/sandstone.jpg');
  background-size: cover;
  color: #0c3069;
  position: relative;
  width: 50px;
  height: 50px;
  margin: 0px 10px;
}

.rulesrow textarea {
    width: 90%;
    border: none;
    background: transparent;
}

.donebutton {
      position: absolute;
    bottom: 30px;
    background: #32da3f;
    font-size: 12pt;
    padding: 10px 30px;
    color: white;
    border: none;
    border-radius: 5px;
    font-weight: bold;
    margin: auto;
    width: 150px;
    margin-left: -75px;
}

.menu {
    position: absolute;
    top: 50px;
    right: 0;
    left: 0;
}

.menu button {
    background-image: url('./assets/sandstone.jpg');
    background-size: cover;
    margin: 10px;
    padding: 5px;
    font-size: 10pt;
    font-weight: bold;
    color: #415573;
    border: 4px outset #a6a8ab45;
    border-radius: 5px;
}

.boardcontainer {
  position: absolute;
  top: 0;
  bottom: 100px;
  left: 0;
  right: 0;
  margin: 0px;
  perspective: 1000px;
  user-select: none;
}


.boardcontainer > div {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  backface-visibility: hidden;
}

.boardcontainer > .prevLevel {
  animation: Level-fade-out 1s linear;
  opacity: 0;
}

@keyframes Level-fade-out {
  0% {
    transform: rotateX(0);
    opacity: 1;
  }
  99% {
    opacity: 1;
  }
  100%
  {
    transform: rotateX(-180deg);
    opacity: 0;
  }
}

.boardcontainer > .nextLevel {
  animation: Level-fade-in 1s linear;
}

@keyframes Level-fade-in {
  0% {
    opacity: 0;
    transform: rotateX(180deg);
  }
  1% {
    opacity: 1;
  }
  100% {
    opacity: 1;
    transform: rotateX(0);
  }
}



.board {
  position: relative;
  margin: 20px auto;
  color: white;
  background-image: url('./assets/sandstone.jpg');
  background-size: cover;
  border-radius: 5px;
}

.board::before {
  content: "";
  position: absolute;
  left: 0px;
  top: 0px;
  right: 0px;
  bottom: 0px;
  background-color: #898e9c;
  border: 5px outset #7c7e84;
  opacity: 0.5;
  border-radius: 5px;
}

.board::after {
   content: "";
    position: absolute;
    left: 0px;
    top: 0px;
    right: 0px;
    bottom: 0px;
    background-color: transparent;
    border: 5px outset #8c98bb;
    opacity: 0.3;
    border-radius: 5px;
  }

.tile {
  position: absolute;
  border: 2px outset #a9a2a259;
  box-sizing: border-box;
}

.tile.symbol::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    mask-position: center;
    background-size: contain;
    mask-repeat: no-repeat;
    background-color: currentColor;
  }

.tile.symbol-lock::after {
  mask-image: url(./assets/icons/iconmonstr-lock-3.svg);
}
.tile.symbol-tree::after {
  mask-image: url(./assets/icons/iconmonstr-tree-1.svg);
}
.tile.symbol-drop::after {
  mask-image: url(./assets/icons/iconmonstr-drop-1.svg);
}
.tile.symbol-square::after {
  mask-image: url(./assets/icons/iconmonstr-shape-1.svg);
}
.tile.symbol-circle::after {
  mask-image: url(./assets/icons/iconmonstr-shape-19.svg);
}
.tile.symbol-diamond::after {
  mask-image: url(./assets/icons/iconmonstr-shape-21.svg);
}
.tile.symbol-octagon::after {
  mask-image: url(./assets/icons/iconmonstr-shape-25.svg);
}
.tile.symbol-blob::after {
  mask-image: url(./assets/icons/iconmonstr-shape-27.svg);
}
.tile.symbol-sun::after {
  mask-image: url(./assets/icons/iconmonstr-shape-29.svg);
}

.tile.symbol-react::after {
  mask-image: url(./assets/icons/react.svg);
}

.board.completed .tile.invalid::before {
  content: "";
  position: absolute;
  top: 5px;
  left: 5px;
  right: 5px;
  bottom: 5px;
  background-color: red;
  animation: invalid-tile-pulse infinite alternate 500ms linear;
  box-shadow: 0 0 10px red, 0 0 10px red, 0 0 10px red, 0 0 10px red;
}

@keyframes invalid-tile-pulse {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}


.vertex {
  position: absolute;
  background-color: #b1ae9c;
  font-size: 8pt;
  color: white;
}

.vertex.entry {
  //background-color: #000000;
}

.vertex.exit {
  //background-color: #000000ff;
  border-radius: 50%;
}

.vertex.visited {
  background-color: #EFE3AD;
  box-shadow: 0 0 10px #EFE3AD, 0 0 10px #EFE3AD, 0 0 10px #EFE3AD, 0 0 10px #EFE3AD;
}

.edge {
  position: absolute;
  background-color: #b1ae9c;
  font-size: 8pt;
}

.edge.visited {
  background-color: #EFE3AD;
  box-shadow: 0 0 10px #EFE3AD, 0 0 10px #EFE3AD, 0 0 10px #EFE3AD, 0 0 10px #EFE3AD;
}

.edge.broken {
  display: none;
}

.creditfooter {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  color: #a5a7ad;
  font-size: 10pt;
  font-weight: bold;
}

.creditfooter > div {
  max-width: 600px;
  margin: 5px auto;
  text-align: center;
}

.creditfooter a {
  color: inherit;
}
